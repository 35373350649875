import React from 'react';
import { FirestoreContext } from '../../../../utils/store/context/firestoreContext';

const EnurmBreakdown = () => {
	const { questions, getQuestions } = React.useContext(FirestoreContext);
	const [filteredQs, setFilteredQs] = React.useState([]);
	const [categories, setCategories] = React.useState([]);
	const [subCategories, setSubCategories] = React.useState();
	const [showList, setShowList] = React.useState(false);
	// const questions = JSON.parse(localStorage.getItem('prepmed-questions'));

	React.useEffect(() => {
		// console.info(questions.length);
		questions.length <= 0 && getQuestions();
		// eslint-disable-next-line
	}, []);

	// React.useEffect(() => {
	// 	categories && getSubCategories();
	// 	// eslint-disable-next-line
	// }, categories);

	const handleChange = (e) => {
		let fQs = questions.filter((q) => {
			return q.enurm === e.target.value;
		});
		setFilteredQs(fQs);
		getCategories(fQs);
	};

	const getCategories = (fQs) => {
		let thisTestsCategories = [];
		fQs.forEach((i) => {
			// console.info(i.category);
			return thisTestsCategories.push(i.category);
		});
		thisTestsCategories = new Set(thisTestsCategories);
		// console.info(thisTestsCategories);
		getSubCategories(thisTestsCategories, fQs);
		setCategories([...thisTestsCategories]);
		setShowList(true);
	};

	const getSubCategories = (categories, fQs) => {
		let categoriesObject = {};
		let tempSet;
		// COLLECT QUESTIONS
		categories.forEach((category) => {
			let tempArrayOfSubCategories = [];
			let thisCatQuestions = fQs.filter((question) => {
				return question.category === category;
			});

			// COLLECT UNIQUE SUB CATEGORIES
			thisCatQuestions.forEach((question) => {
				if (question.hasOwnProperty('subCategory')) {
					return tempArrayOfSubCategories.push(question.subCategory);
				}
				return tempArrayOfSubCategories.push('NoSubCategory');
			});
			tempSet = new Set(tempArrayOfSubCategories);

			// COUNT EACH SUB CAT OCURRANCE
			let collectionOfSubCatsCounted = [];
			tempSet.forEach((subCat) => {
				// GET NUMBER OF 'noSubCategory' OCURRENCES
				if (subCat === 'NoSubCategory') {
					let noSubCount = tempArrayOfSubCategories.filter((i) => {
						return i === 'NoSubCategory';
					});

					collectionOfSubCatsCounted.push(
						`NoSubCategory: ${noSubCount.length}`
					);
				} else {
					// COUNT SUB CATEGORY OCURRENCES
					let tempCount = thisCatQuestions.filter(
						(question) => question.subCategory === subCat
					);
					collectionOfSubCatsCounted.push(`${subCat}: ${tempCount.length}`);
				}
			});

			categoriesObject[prepCategory(category)] = collectionOfSubCatsCounted;
		});

		setSubCategories(categoriesObject);
	};

	const prepCategory = (category) => {
		let val = category.replace(/ /g, '');
		return val;
	};

	return (
		<div>
			<div className='input-field'>
				<select
					// className='browser-default'
					id='enurm'
					name='enurm'
					className='browser-default'
					style={{ width: 'auto' }}
					defaultValue={'default'}
					onChange={handleChange}
					tabIndex='0'
					autoFocus>
					<option value='default' disabled>
						-- Elige un ENURM --
					</option>
					{/* <option value='prepmed'>PrepMep</option> */}
					<option value='evaluacion-1'>Evaluación Diagnóstica 1</option>
					<option value='evaluacion-2'>Evaluación Diagnóstica 2</option>
					<option value='evaluacion-3'>Evaluación Diagnóstica 3</option>
					<option value='enurm-2024'>ENURM 2024</option>
					<option value='enurm-2023-A'>ENURM 2023 A</option>
					<option value='enurm-2023-B'>ENURM 2023 B</option>
					<option value='enurm-2023-C'>ENURM 2023 C</option>
					<option value='enurm-2023-D'>ENURM 2023 D</option>
					<option value='enurm-2023-E'>ENURM 2023 E</option>
					<option value='enurm-2022-A'>ENURM 2022 A</option>
					<option value='enurm-2022-B'>ENURM 2022 B</option>
					<option value='enurm-2022-C'>ENURM 2022 C</option>
					<option value='enurm-2022-D'>ENURM 2022 D</option>
					<option value='enurm-2021-A'>ENURM 2021 A</option>
					<option value='enurm-2021-B'>ENURM 2021 B</option>
					<option value='enurm-2021-C'>ENURM 2021 C</option>
					<option value='enurm-2020'>ENURM 2020</option>
					<option value='enurm-2019'>ENURM 2019</option>
					<option value='enurm-2018'>ENURM 2018</option>
					<option value='enurm-2017'>ENURM 2017</option>
					<option value='enurm-2016'>ENURM 2016</option>
					<option value='enurm-2015'>ENURM 2015</option>
					<option value='enurm-2014'>ENURM 2014</option>
					<option value='enurm-2013'>ENURM 2013</option>
					<option value='enurm-2012'>ENURM 2012</option>
					<option value='enurm-2011'>ENURM 2011</option>
					<option value='enurm-2010'>ENURM 2010</option>
					<option value='enurm-2009'>ENURM 2009</option>
					<option value='enurm-2008'>ENURM 2008</option>
				</select>
			</div>
			<div>
				{showList &&
					categories.length > 0 &&
					categories.map((category) => {
						let categoryAsProp = prepCategory(category);

						return (
							<div key={category}>
								<p>
									{category}:{' '}
									{filteredQs.filter((q) => q.category === category).length}
									{subCategories && (
										<ul className='m-0 ml-2'>
											{/* {subCategories.hasOwnProperty(categoryAsProp) && (
											<li>True: {categoryAsProp} </li>
										)} */}

											{subCategories.hasOwnProperty(categoryAsProp) &&
												subCategories[categoryAsProp].map((subCat) => {
													return (
														<li
															key={subCat}
															style={{
																fontSize: 'small',
																color: 'var(--gray2)',
															}}>
															{subCat}
														</li>
													);
												})}
										</ul>
									)}
								</p>
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default EnurmBreakdown;
